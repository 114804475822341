import { NavbarNew, SidebarNew } from "../components/Navigation"
import Header from "../components/Header"
import Footer from "../components/Footer"
import { ImageWebp } from "../components/ResponsiveImage"

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useRef, useEffect } from "react";

import ex from "../styles/actividadesNew.module.css"
import cx from "classnames"

gsap.registerPlugin(ScrollTrigger);

export default function ActividadesDos() {
    {/* Aparecer botones */}
    const flotantes = useRef(null);
    const timeline_flotantes = useRef();
    useEffect(() => {
        timeline_flotantes.current = gsap.timeline({
            scrollTrigger: {
                trigger: '.actividades_agapornis',
                markers: false,
                start: '100% bottom',
                end: '0 0',
                scrub: true,
            },
        });
        timeline_flotantes.current.from(flotantes.current, {opacity: 0, duration: 1});
    });

    { /* Movimiento raya */ }
    const agapornis = useRef(null);
    const timeline_agapornis = useRef();
    useEffect(() => {
        timeline_agapornis.current = gsap.timeline({
            scrollTrigger: {
                trigger: '.actividades_rayas_img__raya',
                markers: false,
                start: '0 bottom',
                end: '+=100 0',
                scrub: true,
            },
        });
        timeline_agapornis.current.from(agapornis.current, {y: 50, duration: 3,});
    });

    return (
        <>
            <NavbarNew engLink={ "/eng/activities" }/>
            <SidebarNew engLink={ "/eng/activities" }/>
            <Header routeName="actividadesDos" altName="Pecera redonda" titleText="ACTIVIDADES"/>
            
            {/* Botón flotante */}
            <div ref={ flotantes } className="flotantes">
                <a href="https://api.whatsapp.com/send?phone=%2B526691390134" title="Whatsapp" className="flotantes_wp">
                    <img src="/images/whatsapp.png" alt="Whatsapp logo" title="Whatsapp logo"/>
                </a>
                <div className="separator"></div>
                <a href="https://tickets.granacuario.com/" title="Tickets" target="_blank" className="flotantes_tickets">
                    <img src="/images/tickets.png" alt="Tickets logo" title="Tickets logo"/>
                </a>
            </div>

            {/* Actividades */}
            <div className={ cx(ex.actividades, 'actividades') }>
                {/* Alimentación de rayas */}
                <div className={ cx(ex.actividades_rayas, 'actividades_rayas') }>
                    <img className={ ex.actividades_rayas__bg } src="/images/actividadesNew/backgroundRayas.svg"/>
                    <div className={ cx(ex.actividades_rayas_img) }>
                        <picture>
                            <source srcSet="/images/actividadesNew/raya@960.png" media="(min-width:900px)"/>
                            <source srcSet="/images/actividadesNew/raya@750.png" media="(max-width:899px) and (min-width: 600px)"/>
                            <img className={ cx(ex.actividades_rayas_img__raya, 'actividades_rayas_img__raya') } src="/images/actividadesNew/raya@400.png" alt="Alimentación de rayas" title="Alimentación de rayas" />
                        </picture>
                    </div>
                    <div className={ ex.actividades_rayas_title }>
                        <div className={ ex.actividades_rayas_title__t1 }>Alimentación</div>
                        <div className={ cx(ex.actividades_rayas_title__t2, 'title') }>DE RAYAS</div>
                    </div>
                    <div className={ ex.actividades_rayas_disclaimer }>¡Consulta nuestros horarios!<br/>*De venta en nuestras instalaciones</div>
                </div>

                {/* Alimentación de agapornis */}
                <div className={ cx(ex.actividades_agapornis, 'actividades_agapornis') }>
                    <div className={ cx(ex.actividades_agapornis_img) }>
                        <picture>
                            <img ref={ agapornis } src="/images/actividadesNew/agapornis@750.png" alt="Alimentación de agapornis" title="Alimentación de agapornis" />
                        </picture>
                    </div>
                    <div className={ ex.actividades_agapornis_title }>
                        <div className={ ex.actividades_agapornis_title__t1 }>Alimentación</div>
                        <div className={ cx(ex.actividades_agapornis_title__t2, 'title') }>DE AGAPORNIS</div>
                    </div>
                    <div className={ ex.actividades_agapornis_disclaimer }>¡Consulta nuestros horarios!<br/>*De venta en nuestras instalaciones</div>
                </div>

                <div className={ cx(ex.doble_actividad_con) }>
                    {/* Doble actividad */}
                    <div className={ cx(ex.doble_actividad_one) }>
                        {/* Hábitat de los pinguinos */}
                        <div className={ ex.actividades_pinguinos }>
                            <div className={ ex.actividades_pinguinos_img }>
                                <img src="/images/actividadesNew/habitat_pinguinos.png" alt="" />
                            </div>
                            <div className={ ex.actividades_pinguinos_title }>
                                <div className={ ex.actividades_pinguinos_title_t1 }>Hábitat de los</div>
                                <div className={ cx(ex.actividades_pinguinos_title_t2, 'title') }>PINGÜINOS</div>
                                <div className={ ex.actividades_pinguinos_title_t3 }>Visita el hábitat de los pingüinos de Humboldt, las aves más tiernas y divertidas.</div>
                                <div className={ ex.actividades_pinguinos_title_disclaimer }>¡Consulta nuestros horarios!<br/>* Incluida en tu boleto "Visita Gran Acuario Mazatlan LYP".</div>
                            </div>
                        </div>
                        {/* Interacción con pinguinos */}
                        <div className={ ex.actividades_interaccion_pinguinos }>
                            <img className={ ex.actividades_interaccion_pinguinos_bg } src="/images/actividadesNew/backgroundInteraccion.svg" alt="" />
                            <div className={ ex.actividades_interaccion_pinguinos_title }>
                                <div className={ ex.actividades_interaccion_pinguinos_title_t1 }>Interacción</div>
                                <div className={ cx(ex.actividades_interaccion_pinguinos_title_t2, 'title') }>CON PINGÜINOS</div>
                                <div className={ ex.actividades_interaccion_pinguinos_title_p }>En esta increíble experiencia podrás verlos frente a ti, alimentarlos y tendrás la oportunidad de llevar una linda fotografía.</div>
                            </div>
                            <div className={ ex.actividades_interaccion_pinguinos_disclaimer }>¡Consulta nuestros horarios!<br/>*De venta en nuestras instalaciones</div>
                            <div className={ ex.actividades_interaccion_pinguinos_img }>
                                <picture>
                                    <img src="/images/actividadesNew/interaccion_pinguinos@960.png" alt="" />
                                </picture>
                            </div>
                        </div>
                    </div>

                    {/* Doble actividad */}
                    <div className={ cx(ex.doble_actividad_two) }>
                        {/* Tour VIP */}
                        <div className={ ex.actividades_tour }>
                            <picture>
                                <source srcSet="/images/actividadesNew/tour_vip@670.png" media="(min-width:900px)"/>
                                <img className={ ex.actividades_tour_img } src="/images/actividadesNew/tour_vip@450.png" alt="" />
                            </picture>
                            <div className={ ex.actividades_tour_des }>
                                <div className={ cx(ex.actividades_tour_des_t1, 'title') }>TOUR VIP</div>
                                <div className={ ex.actividades_tour_des_p }>¡Ponte el casco y acompáñanos en este recorrido especial! Aquí conocerás el corazón de Gran Acuario Mazatlán visitando el área de Soporte de Vida. </div>
                            </div>
                            <div className={ ex.actividades_tour_des_disclaimer }>¡Consulta nuestros horarios!<br/>*De venta en nuestras instalaciones</div>
                        </div>
                        {/* Conoce a los lobos marinos */}
                        <div className={ ex.actividades_lobos }>
                            <div className={ ex.actividades_lobos_tite }>
                                <div className={ ex.actividades_lobos_tite_t1 }>Conoce a los</div>
                                <div className={ cx(ex.actividades_lobos_tite_t2, 'title') }>LOBOS MARINOS</div>
                                <div className={ ex.actividades_lobos_tite_p }>Déjate sorprender por su gran energía e increíbles habilidades.</div>
                                <div className={ ex.actividades_lobos_tite_disclaimer }>¡Consulta nuestros horarios!<br/>*Incluida en tu boleto "Visita Gran Acuario Mazatlan LYP".</div>
                            </div>
                            <div className={ ex.actividades_lobos_img }>
                                <img src="/images/actividadesNew/lobos_marinos@960.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Experiencia snorkel */}
                <div className={ ex.actividades_snorkel }>
                    <div className={ ex.actividades_snorkel_img }>
                        <img src="/images/actividadesNew/snorkel@750.png" alt="" />
                    </div>
                    <div className={ ex.actividades_snorkel_des }>
                        <img className={ ex.actividades_snorkel_bg } src="/images/actividadesNew/backgroundRayas.svg"/>
                        <div className={ cx(ex.actividades_snorkel_des_t1, 'title') }>EXPERIENCIA SNORKEL</div>
                        <div className={ ex.actividades_snorkel_des_t2 }>Disfruta esta sorprendente experiencia Snorkeling, donde podrás conocer todas las especies de nuestro Hábitat Oceánico, todo el tiempo estarás acompañado de Buzos Profesionales con una duración de 90 minutos incluyendo una breve charla de inducción. Vive la magia de interactuar con especies del Mar de Cortes.</div>
                        <div className={ ex.actividades_snorkel_des_t2 }>Esta experiencia no incluye tu boleto de entrada. Toma en cuenta que deberás de presentarte 30 min antes del horario de tu experiencia.</div>
                        <div className={ ex.actividades_snorkel_des_t2 }>Para disfrutar esta experiencia compra aquí tus boletos, o contactanos en el (669) 689.05.01 ext. 1032 / 1055</div>
                    </div>
                </div>

                {/* Experiencia buceo */}
                <div className={ ex.actividades_buceo }>
                    <div className={ ex.actividades_buceo_des }>
                        <div className={ cx(ex.actividades_buceo_des_t1, 'title') }>EXPERIENCIA BUCEO</div>
                        <div className={ ex.actividades_buceo_des_t2 }>Disfruta el Gran Acuario Mazatlán al vivir la experiencia de sumergirte en nuestro Hábitat Oceánico con más de 2.6 millones de litros de agua, donde tendrás la oportunidad de nadar junto a Tiburones, Rayas, Meros Goliat y muchas más especies.</div>
                        <div className={ ex.actividades_buceo_des_t2 }>Recuerda que para ingresar a esta experiencia debes presentar tu credencial vigente de Buzo Certificado.</div>
                        <div className={ ex.actividades_buceo_des_t2 }>Esta experiencia incluye tu boleto de entrada. Toma en cuenta que deberás de presentarte 30 min antes del horario de tu experiencia.</div>
                        <div className={ ex.actividades_buceo_des_t2 }>Para cualquier duda, por favor contactanos en (669) 689.05.01 ext. 1032 / 1055, o al correo ventasgrupos@granacuario.com</div>
                    </div>
                    <div className={ ex.actividades_buceo_img }>
                        <img src="/images/actividadesNew/buceo@750.png" alt="" />
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}